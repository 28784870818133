import { UnusualApi } from '@/api/attendance/unusual/UnusualApi';

export class UnusualService {
    private api = new UnusualApi();

    public getErrorDetail(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getErrorDetail(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public saveErrorDetail(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.saveErrorDetail(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public delete(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.delete(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //获取详情 
    public getInfo(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getInfo(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}