import AttendanceBaseService from '../base/AttendanceBaseService';

export class UnusualApi extends AttendanceBaseService {
    constructor() {
        super("unusual");
    }

    /**
   * 获取详细
   */
    public getErrorDetail(params: any): Promise<any> {
        return this.request('detail', this.rest.METHOD_GET, params);
    }
    /**
   * 保存异常打卡申请
   */
    public saveErrorDetail(params: any): Promise<any> {
        return this.request('save', this.rest.METHOD_POST, params);
    }
    /**
      * 删除
      */
    public delete(params: any): Promise<any> {
        return this.request('delete?=' + params.id, this.rest.METHOD_POST, params);
    }
    //获取详细 
    public getInfo(params: any): Promise<any> {
        return this.request('detail', this.rest.METHOD_GET, params);
    }
}