
import { defineComponent, reactive, onMounted, ref } from "vue";
import { alertController } from "@ionic/vue";
import Calendar from "@/components/Calendar.vue";
import { useRouter, useRoute } from "vue-router";
import { RecordService } from "@/services/attendance/record/RecordService";
import { UnusualService } from "@/services/attendance/unusual/UnusualService";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils";
import moment from "moment";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "AbnormalApplyPage",
  components: {
    Header,
    Calendar,
  },
  setup() {
    const recordService = new RecordService();
    const unusualService = new UnusualService();
    const workflowService = new WorkflowService();
    const dataList = reactive({ data: new Array() });
    const endDate = ref("");
    const detail = ref({
      id: "",
      userName: "",
      orgName: "",
      date: "2",
      morningSignin: "",
      morningSignout: "",
      morningStatusName: "",
      afternoonSignin: "",
      afternoonSignout: "",
      afternoonStatusName: "",
      isEnablePmSign: 0,
      morningStatus: -1,
      morningLeaveCode: "",
      afternoonStatus: -1,
      afternoonLeaveCode: "",
      orgId: "",
      recordId: "",
      userId: "",
    });
    const cityDetail = ref({ signStandard: 1 });
    const id = ref("");
    const route = useRoute();
    const router = useRouter();
    const timeIn1 = ref("");
    const timeOut1 = ref("");
    const timeIn2 = ref("");
    const timeOut2 = ref("");
    const timeIn1Value = ref("");
    const timeOut1Value = ref("");
    const timeIn2Value = ref("");
    const timeOut2Value = ref("");
    const businessId = ref("");
    const procId = ref("");
    const taskId = ref("");
    const procStatus = ref(-1);
    const currUser: any = SystemUtils.loginUser;
    const thisUserId = currUser.id;
    const reason = ref("");

    const getDetail = () => {
      let params = { id: id.value };
      recordService
        .getDetail(params)
        .then((res: any) => {
          detail.value = res;
          detail.value.recordId = id.value;
          initDateInfo();
          showBtn();
        })
        .catch(() => {});
    };
    const getAbnormalInfo = () => {
      let params = { id: businessId.value };
      unusualService
        .getErrorDetail(params)
        .then((res: any) => {
          detail.value = res;
          initDateInfo();
          showBtn();
        })
        .catch(() => {});
    };
    const initDateInfo = () => {
      timeIn1.value = detail.value.morningSignin;
      timeOut1.value = detail.value.morningSignout;
      timeIn2.value = detail.value.afternoonSignin;
      timeOut2.value = detail.value.afternoonSignout;

      if (timeIn1.value != null) {
        let timeIn1Date = new Date(
          detail.value.date.replace(/\-/g, "/") + " " + timeIn1.value
        );
        timeIn1Value.value = moment(timeIn1Date).format("HH:mm");
      }
      if (timeOut1.value != null) {
        let timeOut1Date = new Date(
          detail.value.date.replace(/\-/g, "/") + " " + timeOut1.value
        );
        timeOut1Value.value = moment(timeOut1Date).format("HH:mm");
      }
      if (timeIn2.value != null) {
        let timeIn2Date = new Date(
          detail.value.date.replace(/\-/g, "/") + " " + timeIn2.value
        );
        timeIn2Value.value = moment(timeIn2Date).format("HH:mm");
      }
      if (timeOut2.value != null) {
        let timeOut2Date = new Date(
          detail.value.date.replace(/\-/g, "/") + " " + timeOut2.value
        );
        timeOut2Value.value = moment(timeOut2Date).format("HH:mm");
      }
    };
    const getCityInfo = () => {
      let params = { userId: thisUserId };
      recordService
        .getAttenCity(params)
        .then((res: any) => {
          cityDetail.value = res;
          showBtn();
        })
        .catch(() => {});
    };
    const showMorningIn = ref(false);
    const showMorningOut = ref(false);
    const showAfternoonIn = ref(false);
    const showAfternoonOut = ref(false);
    const showBtn = () => {
      showMorningIn.value = false;
      showMorningOut.value = false;
      showAfternoonIn.value = false;
      showAfternoonOut.value = false;
      //考勤状态！=3||（==3&&哺乳假）的可以修改，其余不能改
      //不启用下午打卡
      if (detail.value.isEnablePmSign == 0) {
        //计算迟到早退
        if (cityDetail.value.signStandard == 1) {
          if (
            detail.value.morningStatus != 3 ||
            (detail.value.morningStatus == 3 &&
              detail.value.morningLeaveCode == "LeaveType_Lactation")
          ) {
            showMorningIn.value = true;
          }
          if (
            detail.value.afternoonStatus != 3 ||
            (detail.value.afternoonStatus == 3 &&
              detail.value.afternoonLeaveCode == "LeaveType_Lactation")
          ) {
            showAfternoonOut.value = true;
          }
        }
        //满足工时
        else if (cityDetail.value.signStandard == 2) {
          showMorningIn.value = true;
          showAfternoonOut.value = true;
        }
      }
      //启用下午打卡
      else if (detail.value.isEnablePmSign == 1) {
        //计算迟到早退
        if (cityDetail.value.signStandard == 1) {
          if (
            detail.value.morningStatus != 3 ||
            (detail.value.morningStatus == 3 &&
              detail.value.morningLeaveCode == "LeaveType_Lactation")
          ) {
            showMorningIn.value = true;
            showMorningOut.value = true;
          }
          if (
            detail.value.afternoonStatus != 3 ||
            (detail.value.afternoonStatus == 3 &&
              detail.value.afternoonLeaveCode == "LeaveType_Lactation")
          ) {
            showAfternoonIn.value = true;
            showAfternoonOut.value = true;
          }
        }
        //满足工时
        else if (cityDetail.value.signStandard == 2) {
          showMorningIn.value = true;
          showMorningOut.value = true;
          showAfternoonIn.value = true;
          showAfternoonOut.value = true;
        }
      }
    };
    const selectTimeChangeHandler = (ev: any, type: any) => {
      if (ev.detail.value != null) {
        let time = ev.detail.value;
        if (ev.detail.value.length > 5) {
          time = moment(ev.detail.value).format("HH:mm");
        }
        switch (type) {
          case "morningSignin":
            timeIn1.value = time;
            break;
          case "morningSignout":
            timeOut1.value = time;
            break;
          case "afternoonSignin":
            timeIn2.value = time;
            break;
          case "afternoonSignout":
            timeOut2.value = time;
            break;
        }
      }
    };
    const selectDateHandle = (type: any) => {
      switch (type) {
        case "in1":
          let morningSignin: any = document.getElementsByClassName(
            "morning-signin-picker"
          );
          if (morningSignin && morningSignin[0]) {
            let el: HTMLElement = morningSignin[0];
            el.click();
          }
          break;
        case "out1":
          let morningSignout: any = document.getElementsByClassName(
            "morning-signout-picker"
          );
          if (morningSignout && morningSignout[0]) {
            let el: HTMLElement = morningSignout[0];
            el.click();
          }
          break;
        case "in2":
          let afternoonSignin: any = document.getElementsByClassName(
            "afternoon-signin-picker"
          );
          if (afternoonSignin && afternoonSignin[0]) {
            let el: HTMLElement = afternoonSignin[0];
            el.click();
          }
          break;
        case "out2":
          let afternoonSignout: any = document.getElementsByClassName(
            "afternoon-signout-picker"
          );
          if (afternoonSignout && afternoonSignout[0]) {
            let el: HTMLElement = afternoonSignout[0];
            el.click();
          }
          break;
      }
    };
    const isClick = ref(true);
    const singleFinishHandler = (item: any) => {
      saveData();
    };
    const mulFinishHandler = (item: any) => {
      if (item == "0_icon") {
        saveData();
      } else {
        deleteApply();
      }
    };
    const saveData = () => {
      if (detail.value.isEnablePmSign == 0) {
        if (!timeIn1.value && showMorningIn.value) {
          Utils.presentToastWarning("请选择签到时间");
          return;
        }

        if (!timeOut2.value && showAfternoonOut) {
          Utils.presentToastWarning("请选择签退时间");
          return;
        }
      } else if (detail.value.isEnablePmSign == 1) {
        if (!timeIn1 && showMorningIn) {
          Utils.presentToastWarning("请选择上午签到时间");
          return;
        }
        if (!timeOut1 && showMorningOut) {
          Utils.presentToastWarning("请选择上午签退时间");
          return;
        }
        if (!timeIn2 && showAfternoonIn) {
          Utils.presentToastWarning("请选择下午签到时间");
          return;
        }

        if (!timeOut2 && showAfternoonOut) {
          Utils.presentToastWarning("请选择下午签退时间");
          return;
        }
      }

      if (!reason.value.replace(/\s+/g, "")) {
        Utils.presentToastWarning("请填写异常说明");
        return;
      }

      let newMorningSignin = timeIn1.value;
      if (showMorningIn) {
        newMorningSignin = timeIn1.value;
      } else {
        newMorningSignin = detail.value.morningSignin;
      }
      let newMorningSignout = timeOut1.value;
      if (showMorningOut) {
        newMorningSignout = timeOut1.value;
      } else {
        newMorningSignout = detail.value.morningSignout;
      }
      let newAfternoonSignin = timeIn2.value;
      if (showAfternoonIn) {
        newAfternoonSignin = timeIn2.value;
      } else {
        newAfternoonSignin = detail.value.afternoonSignin;
      }
      let newAfternoonSignout = timeOut2.value;
      if (showAfternoonOut) {
        newAfternoonSignout = timeOut2.value;
      } else {
        newAfternoonSignout = detail.value.afternoonSignout;
      }
      //比较时间
      if (detail.value.isEnablePmSign == 0) {
        if (
          new Date(detail.value.date + " " + newMorningSignin) >=
          new Date(detail.value.date + " " + newAfternoonSignout)
        ) {
          Utils.presentToastWarning("签到时间不能晚于签退时间");
          return;
        }
      } else if (detail.value.isEnablePmSign == 1) {
        if (
          new Date(detail.value.date + " " + newMorningSignin) >=
          new Date(detail.value.date + " " + newMorningSignout)
        ) {
          Utils.presentToastWarning("签到时间不能晚于签退时间");
          return;
        }
        if (
          new Date(detail.value.date + " " + newMorningSignout) >=
          new Date(detail.value.date + " " + newAfternoonSignin)
        ) {
          Utils.presentToastWarning("上午签退时间不能晚于下午签到时间");
          return;
        }
        if (
          new Date(detail.value.date + " " + newAfternoonSignin) >=
          new Date(detail.value.date + " " + newAfternoonSignout)
        ) {
          Utils.presentToastWarning("签到时间不能晚于签退时间");
          return;
        }
      }
      let dto = {
        applyTime: new Date(),
        approvalStatus: 3,
        date: detail.value.date,
        newAfternoonSignin: newAfternoonSignin,
        newAfternoonSignout: newAfternoonSignout,
        newMorningSignin: newMorningSignin,
        newMorningSignout: newMorningSignout,
        oldAfternoonSignin: detail.value.afternoonSignin,
        oldAfternoonSignout: detail.value.afternoonSignout,
        oldAfternoonSignstatus: detail.value.afternoonStatusName,
        oldMorningSignin: detail.value.morningSignin,
        oldMorningSignout: detail.value.morningSignout,
        oldMorningSignstatus: detail.value.morningStatusName,
        orgId: detail.value.orgId,
        orgName: detail.value.orgName,
        reason: reason.value,
        recordId: detail.value.recordId,
        userId: detail.value.userId,
        userName: detail.value.userName,
        procId: procId.value,
        taskId: taskId.value,
      };

      if (!isClick.value) {
        console.log("11111");
        return;
      }
      isClick.value = false;
      Utils.confirmDialog1(
        "确认",
        "请确认是否提交异常打卡申请？",
        function () {
          unusualService.saveErrorDetail(dto).then((result: any) => {
            if (result) {
              businessId.value = result.businessId;
              taskId.value = result.taskId;
              procId.value = result.procId;
              //发送
              let paramSend = {
                procId: procId.value,
                taskId: taskId.value,
                listReciveVar: [],
                option: "",
              };
              workflowService
                .send(paramSend)
                .then((result: any) => {
                  if (result) {
                    if (result.result) {
                      Utils.presentToastSuccess("提交成功");
                      setTimeout(() => {
                        router.go(-1);
                      }, 500);
                    } else {
                      isClick.value = true;
                    }
                  }
                })
                .catch((ex: any) => {
                  isClick.value = true;
                });
            } else {
              isClick.value = true;
            }
          });
        },
        function () {
          isClick.value = true;
        }
      );
    };

    const deleteApply = () => {
      if (!isClick.value) return;
      Utils.confirmDialog1("确认", "确认删除该申请？", function () {
        let params = {
          id: businessId.value,
        };
        unusualService
          .delete(params)
          .then((res: any) => {
            isClick.value = true;
            setTimeout(() => {
              router.go(-1);
            }, 500);
          })
          .catch((ex: any) => {
            isClick.value = true;
            Utils.presentToastWarning("删除失败");
          });
      },function () {
          isClick.value = true;
        });
    };

    onMounted(() => {
      if (route.params.procId != null && route.params.procId != "") {
        businessId.value = route.params.businessId as string;
        procId.value = route.params.procId as string;
        taskId.value = route.params.taskId as string;
        procStatus.value = parseInt(route.params.procStatus as string);
        getAbnormalInfo();
      } else if (route.params.id != null && route.params.id != "") {
        id.value = route.params.id as string;
        getDetail();
      }
      getCityInfo();
    });
    return {
      btnIconRight: ["iconduihao"],
      btnSizeRight: ["34px"],
      btnIconRightDelete: ["iconduihao", "iconlajitong"],
      btnSizeRightDelete: ["34px", "34px"],
      detail,
      selectTimeChangeHandler,
      singleFinishHandler,
      mulFinishHandler,
      timeIn1,
      timeOut1,
      timeIn2,
      timeOut2,
      timeIn1Value,
      timeOut1Value,
      timeIn2Value,
      timeOut2Value,
      showMorningOut,
      showAfternoonIn,
      reason,
      selectDateHandle,
      procStatus,
      businessId,
    };
  },
});
